import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '255, 255, 255',
		'primary-dark': '242, 242, 245',
		'accent': '55, 136, 208',
		'accent-plus': '255, 255, 255',
	},
});
