import {lazy} from 'react';
import {loadComponent} from 'app/selectors/Helpers';

const Refund = loadComponent(lazy(() => import('app/containers/ResendEmailPopupContainer')));

export default [
	{
		path: "/refund",
		component: Refund,
		key: 1
	}
];
